import { Col } from 'antd';
import { CSSProperties } from 'react';
import styled from 'styled-components';

interface LocationMarkerProps {
  markerColor: string;
}

export const GoogleMapStyled: CSSProperties = {
  width: '100%',
  minHeight: '50vh',
  borderRadius: '0.75rem',
};

export const PinMarker = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50% 50% 50% 0;
  color: white;
  background-color: var(--notification-error-color);
  transform: rotate(-45deg) translate(40%, -120%);
  display: flex;
  justify-content: center;
  align-items: center;

  :first-child {
    transform: rotate(45deg) !important;
    max-width: 2rem;
  }
`;

export const LocationPinMarker = styled.div<LocationMarkerProps>`
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50% 50% 50% 0;
  color: white;
  background-color: ${(props) => props.markerColor};
  transform: rotate(-45deg) translate(40%, -120%);
  display: flex;
  justify-content: center;
  align-items: center;

  :first-child {
    transform: rotate(45deg) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
`;

export const LocationMarkerContainer = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
`;

export const LocationMarker = styled.div<LocationMarkerProps>`
  color: white;
  border-radius: 50%;
  width: 0.7rem;
  height: 0.7rem;
  background-color: ${(props) => `${props.markerColor}`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.9;
  z-index: 400;

  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    border-radius: 50%;
    background-color: transparent;
    z-index: -1;
    cursor: pointer;
  }
`;

export const LocationMarkerTooltip = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #707070;
  padding: 5px 20px 10px 10px;
  border-radius: 5px;
  min-width: 300px;
  width: fit-content;
  text-align: center;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  //opacity: 0;
  visibility: visible;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  pointer-events: auto;
  cursor: text;
  user-select: text;
  z-index: 401;

  ${LocationMarker}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

export const LocationMarkerTooltipHeader = styled.div`
  margin-bottom: 0.8rem;
  font-size: 24px;
  font-weight: 700;
  line-height: 2.188rem;
  letter-spacing: 0em;
  text-align: left;
  color: #263668;
`;

export const LocationMarkerTooltipCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  z-index: 402;
`;

export const LocationMarkerTooltipBody = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  gap: 1rem;
`;

export const ModalMapContainer = styled.div`
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.75rem;
  border: 1px solid var(--primary-color);
`;

export const ResumeContainer = styled.div`
  border-radius: 0.75rem;
  margin: 1rem 2rem;
  padding: 0.75rem;
`;

export const ResumeItensTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: Mulish;
`;

export const ResumeItensContent = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-family: Mulish;
  margin-bottom: 0.5rem;
`;

export const ConcreteMixerMarker = styled.div`
  color: white;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: unset;
`;

// Modal Trip Resume

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InfoContainerContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7rem;
`;

export const InfoTitle = styled.h4`
  font-size: 14px;
  font-weight: bold;
  margin-left: 0.75rem;
  margin-bottom: -0.5rem;
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;
