import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { HeaderReports } from '@app/components/common/HeaderReports/HeaderReports';
import { Input } from '@app/components/common/inputs/Input/Input';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import appSettings from '@app/config/appsettings';
import { GraphicDataModel, TripAnalyticModel } from '@app/domain/interfaces/report/reportTripAnalyticModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeader } from '@app/store/slices/headerSlice';
import {
  Circle,
  GoogleMap,
  GoogleMapProps,
  OverlayView,
  Polygon,
  useJsApiLoader,
  Polyline,
} from '@react-google-maps/api';
import { Col, Divider, Row } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import HomeIcon from '@app/assets/icons/HomeIcon.svg';
import BalaoBetoneiraIcon from '@app/assets/icons/BalaoBetoneiraIcon.svg';
import { LocalizationModel } from '@app/domain/interfaces/report/reportConcreteMixerModel';
import IReportService, { ReportService } from '@app/services/reportService';
import { notificationController } from '@app/controllers/notificationController';

import * as S from './styles';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import moment from 'moment';
import ModalTripGraphic from './components/ModalTripGraphic';
import { renderViagemEstadoTexto } from '@app/utils/utils';
import ModalTripResume from './components/ModalTripResume';
import IconCaminhaoBetoneiraEsquerda from '@app/assets/slump-icons/IconCaminhaoBetoneiraEsquerda';
import { useParams } from 'react-router-dom';

const supermixLocation = {
  lat: -19.878877423869252,
  lng: -43.97931295928024,
  raio: 150,
};

const reportService: IReportService = new ReportService();

const TripAnalyticDashboard: React.FC = () => {
  const { idViagem } = useParams();
  const dispatch = useAppDispatch();
  const mapRef = useRef<google.maps.Map | null>(null);
  const controlsRef = useRef<HTMLDivElement | null>(null);
  const [centerMap, setCenterMap] = useState<LocalizationModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tripToSearch, setTripToSearch] = useState<string>('');
  const [tripReport, setTripReport] = useState<TripAnalyticModel | null>(null);
  const [visibleTooltipIndex, setVisibleTooltipIndex] = useState<number | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalResumeVisible, setModalResumeVisible] = useState<boolean>(false);

  const { isLoaded } = useJsApiLoader({
    id: 'mixer-dashboard-map',
    googleMapsApiKey: appSettings().MAPS_API_KEY,
    libraries: ['places', 'geometry', 'visualization'],
  });

  const handleChangeTripToSearch = (value: string) => {
    setTripToSearch(value);
  };

  const handleSearchTripClick = () => {
    setTripReport(null);
    fetchTripAnalyticReport();
  };

  const handleSendMarkerColor = (index: number, alert?: boolean): string => {
    if (alert) return '#ff0022';

    switch (index) {
      default:
        return '#FFF';
      case 1:
        return '#1C213C';
      case 2:
        return '#790010';
      case 3:
        return '#00631c';
      case 4:
        return '#ff7700';
      case 5:
        return '#b6ad00';
      case 6:
        return '#504c00';
      case 7:
        return '#0037ff';
      case 8:
        return '#1C213C';
    }
  };

  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setVisibleTooltipIndex(null);
  };

  const toggleTooltipVisibility = (index: number) => {
    setVisibleTooltipIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const fetchTripAnalyticReport = async () => {
    setLoading(true);
    reportService
      .getTripReportAnalytic(Number(idViagem && tripToSearch.length <= 0 ? idViagem : tripToSearch))
      .then((res) => {
        setTripReport(res);
        setLoading(false);
      })
      .catch(() => {
        notificationController.error({ message: 'Erro ao buscar os dados da viagem.' });
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Relatório de análise de viagem',
      }),
    );

    if (idViagem) {
      setTripToSearch(idViagem);
      handleSearchTripClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tripReport?.dadosRemessa) {
      setCenterMap({
        lat: tripReport.dadosRemessa.filialLatitude ?? supermixLocation.lat,
        lng: tripReport.dadosRemessa.filialLongitude ?? supermixLocation.lng,
        raio: tripReport.dadosRemessa.filialRaio ?? supermixLocation.raio,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripReport]);

  const onMapLoad: GoogleMapProps['onLoad'] = useCallback((map: google.maps.Map) => {
    mapRef.current = map;

    map.setOptions({
      gestureHandling: 'cooperative',
      streetViewControl: true,
      mapTypeControl: true,
      fullscreenControl: true,
      zoom: 14,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
    });

    if (map && controlsRef.current) {
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlsRef.current);
    }
  }, []);

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      const bounds = new google.maps.LatLngBounds();
      if (!loading && (!tripReport?.dadosViagem || tripReport?.dadosViagem.length <= 0)) {
        notificationController.warning({
          message: 'Não há informações de viagem para a betoneira selecionada nessa data',
        });
        return;
      }
      if (tripReport?.dadosMapa) {
        tripReport?.dadosMapa?.forEach((v) =>
          bounds.extend(new google.maps.LatLng(v.latitude ? v.latitude : 0, v.longitude ? v.longitude : 0)),
        );

        mapRef.current.fitBounds(bounds);
        mapRef.current.panToBounds(bounds);
      } else if (tripReport?.dadosGps) {
        bounds.extend(
          new google.maps.LatLng(
            tripReport.dadosGps.ultimoPontoAntesDaEmissaoDaNF
              ? tripReport.dadosGps.ultimoPontoAntesDaEmissaoDaNF[0]
              : 0,
            tripReport.dadosGps.ultimoPontoAntesDaEmissaoDaNF
              ? tripReport.dadosGps.ultimoPontoAntesDaEmissaoDaNF[1]
              : 0,
          ),
        );
        mapRef.current.fitBounds(bounds);
        mapRef.current?.panToBounds(bounds);
        mapRef.current.setZoom(15);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, tripReport?.dadosViagem, tripReport?.dadosGps]);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <ModalTripGraphic
        visible={modalVisible}
        setVisible={setModalVisible}
        graphicDetails={tripReport?.dadosGraficos ?? ({} as GraphicDataModel)}
      />
      {tripReport?.dadosResumo && (
        <ModalTripResume
          visible={modalResumeVisible}
          setVisible={setModalResumeVisible}
          tripResume={tripReport.dadosResumo}
        />
      )}

      <PageTitle>Relatórios</PageTitle>
      <Spinner spinning={loading}></Spinner>
      <HeaderReports>
        <BaseForm
          style={{
            width: '100%',
          }}
        >
          <Row gutter={18}>
            <Col xs={24} md={4}>
              <BaseFormInputItem label="Informe o id da viagem:">
                <Input
                  type="text"
                  max={30}
                  min={1}
                  className="ant-input"
                  placeholder="Digite o id da viagem"
                  value={tripToSearch ?? ''}
                  onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                    const char = e.data;
                    if (char && !char.match(/[1-9]/)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    handleChangeTripToSearch(e.target.value);
                  }}
                />
              </BaseFormInputItem>
            </Col>

            <Col style={{ marginTop: '1.75rem' }}>
              <Button
                disabled={!tripToSearch || tripToSearch.length <= 0 || loading}
                type="primary"
                onClick={handleSearchTripClick}
                style={{ width: '14.75rem', height: '3.5rem' }}
              >
                Buscar viagem
              </Button>
            </Col>
          </Row>
        </BaseForm>
      </HeaderReports>
      <Divider type="horizontal" style={{ borderColor: 'var(--disabled-bg-color)' }} />
      {/** Mapa da Viagem */}
      {isLoaded && tripReport && (
        <>
          <BaseForm
            style={{
              marginBottom: '1rem',
              marginLeft: '2rem',
            }}
          >
            <Row gutter={18}>
              <Col xs={24} md={4}>
                <Button type="ghost" onClick={() => setModalVisible(true)} style={{ width: '100%', height: '3.5rem' }}>
                  Ver gráficos
                </Button>
              </Col>
              {tripReport.dadosResumo && (
                <Col xs={24} md={4}>
                  <Button
                    type="ghost"
                    onClick={() => setModalResumeVisible(true)}
                    style={{ width: '100%', height: '3.5rem' }}
                  >
                    Ver resumo
                  </Button>
                </Col>
              )}
            </Row>
          </BaseForm>
          <S.ResumeContainer>
            {tripReport.dadosPaginaPrincipal && (
              <>
                <Row gutter={24} justify={'start'}>
                  <Col xs={24} md={12} lg={6} xxl={6}>
                    <S.ResumeItensTitle>Motorista</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripReport.dadosPaginaPrincipal?.motorista ?? '-'}</S.ResumeItensContent>
                  </Col>
                  <Col xs={24} md={12} lg={6} xxl={6}>
                    <S.ResumeItensTitle>Obra</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripReport.dadosPaginaPrincipal?.obra ?? '-'}</S.ResumeItensContent>
                  </Col>
                  <Col xs={24} md={12} lg={6} xxl={6}>
                    <S.ResumeItensTitle>Filial</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripReport.dadosPaginaPrincipal?.filial ?? '-'}</S.ResumeItensContent>
                  </Col>
                  <Col xs={24} md={12} lg={6} xxl={6}>
                    <S.ResumeItensTitle>Data Emissão NF</S.ResumeItensTitle>
                    <S.ResumeItensContent>
                      {tripReport.dadosPaginaPrincipal.dataEmissaoNotaFiscal ??
                        moment(new Date()).format('DD/MM/yyyy HH:mm')}
                    </S.ResumeItensContent>
                  </Col>
                </Row>
                <Row gutter={24} justify={'start'}>
                  <Col xs={24} md={12} lg={6} xxl={6}>
                    <S.ResumeItensTitle>Veículo</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripReport.dadosPaginaPrincipal?.veiculo ?? '-'}</S.ResumeItensContent>
                  </Col>
                  <Col xs={24} md={12} lg={6} xxl={6}>
                    <S.ResumeItensTitle>Dispositivo</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripReport.dadosPaginaPrincipal?.dispositivo ?? '-'}</S.ResumeItensContent>
                  </Col>
                  <Col xs={24} md={12} lg={6} xxl={6}>
                    <S.ResumeItensTitle>Total de sinais</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripReport.dadosPaginaPrincipal.qtdTotalSinais ?? '-'}</S.ResumeItensContent>
                  </Col>
                  <Col xs={24} md={12} lg={6} xxl={6}>
                    <S.ResumeItensTitle>Falhas</S.ResumeItensTitle>
                    <S.ResumeItensContent>
                      {tripReport.dadosPaginaPrincipal.qtdFalhas
                        ? `${tripReport.dadosPaginaPrincipal.qtdFalhas.toFixed(2)} %`
                        : '-'}
                    </S.ResumeItensContent>
                  </Col>
                  <Col xs={24} md={12} lg={6} xxl={6}>
                    <S.ResumeItensTitle>Tempo primeiro sinal</S.ResumeItensTitle>
                    <S.ResumeItensContent>
                      {tripReport.dadosPaginaPrincipal.tempoAtePrimeiroPontoDeViagemRecebido
                        ? `${tripReport.dadosPaginaPrincipal.tempoAtePrimeiroPontoDeViagemRecebido?.toFixed(2)} mins`
                        : '-'}
                    </S.ResumeItensContent>
                  </Col>
                </Row>
              </>
            )}
            {tripReport.dadosGps && (
              <Row gutter={24} justify={'start'}>
                <Col xs={24} md={12} lg={6} xxl={6}>
                  <S.ResumeItensTitle>Data emissão do último ponto de GPS</S.ResumeItensTitle>
                  <S.ResumeItensContent>
                    {moment(tripReport.dadosGps.dataEmissaoUltimoPontoAntesDaEmissaoDaNF ?? new Date()).format(
                      'DD/MM/yyyy HH:mm',
                    )}
                  </S.ResumeItensContent>
                </Col>
                <Col xs={24} md={12} lg={6} xxl={6}>
                  <S.ResumeItensTitle>Data envio do último ponto de GPS</S.ResumeItensTitle>
                  <S.ResumeItensContent>
                    {moment(tripReport.dadosGps.dataEnvioUltimoPontoAntesDaEmissaoDaNF ?? new Date()).format(
                      'DD/MM/yyyy HH:mm',
                    )}
                  </S.ResumeItensContent>
                </Col>
                <Col xs={24} md={12} lg={6} xxl={6}>
                  <S.ResumeItensTitle>Último ponto era backup</S.ResumeItensTitle>
                  <S.ResumeItensContent>
                    {tripReport.dadosGps.ultimoPontoAntesDaEmissaoDaNFBackup ? 'Sim' : 'Não'}
                  </S.ResumeItensContent>
                </Col>
              </Row>
            )}
          </S.ResumeContainer>
          {tripReport.dadosRemessa && (tripReport.dadosMapa || tripReport.dadosGps) && (
            <GoogleMap
              mapContainerStyle={S.GoogleMapStyled}
              onLoad={onMapLoad}
              center={centerMap?.lat ? centerMap : supermixLocation}
            >
              {tripReport.dadosMapa && (
                <>
                  <Polyline
                    path={tripReport.dadosMapa
                      .filter((m) => m.estadoViagemAtual === 2)
                      .map((m) => new google.maps.LatLng(m.latitude ?? 0, m.longitude ?? 0))}
                    options={{
                      strokeColor: handleSendMarkerColor(2),
                      strokeOpacity: 1,
                      strokeWeight: 4,
                      geodesic: true,
                    }}
                  />
                  <Polyline
                    path={tripReport.dadosMapa
                      .filter((m) => m.estadoViagemAtual === 3)
                      .map((m) => new google.maps.LatLng(m.latitude ?? 0, m.longitude ?? 0))}
                    options={{
                      strokeColor: handleSendMarkerColor(3),
                      strokeOpacity: 1,
                      strokeWeight: 4,
                      geodesic: true,
                    }}
                  />
                  <Polyline
                    path={tripReport.dadosMapa
                      .filter((m) => m.estadoViagemAtual === 4)
                      .map((m) => new google.maps.LatLng(m.latitude ?? 0, m.longitude ?? 0))}
                    options={{
                      strokeColor: handleSendMarkerColor(4),
                      strokeOpacity: 1,
                      strokeWeight: 4,
                      geodesic: true,
                    }}
                  />
                  <Polyline
                    path={tripReport.dadosMapa
                      .filter((m) => m.estadoViagemAtual === 5)
                      .map((m) => new google.maps.LatLng(m.latitude ?? 0, m.longitude ?? 0))}
                    options={{
                      strokeColor: handleSendMarkerColor(5),
                      strokeOpacity: 1,
                      strokeWeight: 4,
                      geodesic: true,
                    }}
                  />
                  <Polyline
                    path={tripReport.dadosMapa
                      .filter((m) => m.estadoViagemAtual === 6)
                      .map((m) => new google.maps.LatLng(m.latitude ?? 0, m.longitude ?? 0))}
                    options={{
                      strokeColor: handleSendMarkerColor(6),
                      strokeOpacity: 1,
                      strokeWeight: 4,
                      geodesic: true,
                    }}
                  />
                  <Polyline
                    path={tripReport.dadosMapa
                      .filter((m) => m.estadoViagemAtual === 7)
                      .map((m) => new google.maps.LatLng(m.latitude ?? 0, m.longitude ?? 0))}
                    options={{
                      strokeColor: handleSendMarkerColor(7),
                      strokeOpacity: 1,
                      strokeWeight: 4,
                      geodesic: true,
                    }}
                  />
                  <Polyline
                    path={tripReport.dadosMapa.map((m) => new google.maps.LatLng(m.latitude ?? 0, m.longitude ?? 0))}
                    options={{
                      strokeColor: '#000000',
                      strokeOpacity: 0.5,
                      strokeWeight: 7,
                      geodesic: true,
                    }}
                  />
                </>
              )}
              {tripReport.dadosMapa &&
                tripReport.dadosMapa.map((v, i) => (
                  <>
                    <OverlayView
                      key={i}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      position={{
                        lat: v.latitude ? v.latitude : 0,
                        lng: v.longitude ? v.longitude : 0,
                      }}
                    >
                      <>
                        <S.LocationMarkerContainer onClick={() => toggleTooltipVisibility(i)} style={{}}>
                          <S.LocationPinMarker markerColor={handleSendMarkerColor(v.estadoViagemAtual ?? 0, v.alerta)}>
                            <h1>{v.estadoViagemAtual}</h1>
                          </S.LocationPinMarker>
                        </S.LocationMarkerContainer>
                        {visibleTooltipIndex === i && (
                          <S.LocationMarkerTooltip>
                            <S.LocationMarkerTooltipHeader>
                              Estado: {renderViagemEstadoTexto(v.estadoViagemAtual ?? 0)}
                            </S.LocationMarkerTooltipHeader>
                            <S.LocationMarkerTooltipBody>
                              <div>
                                Hora: {moment(v?.dataHora).format('HH:mm:ss')}
                                <br />
                                Velocidade: {v?.velocidadeBetoneira} km/h
                              </div>
                              <div>
                                Longitude: {v.latitude ? v.latitude : 0}
                                <br />
                                Latitude: {v.longitude ? v.longitude : 0}
                              </div>
                              <div>
                                Água acumulada no balão: {v.aguaAcumuladaBalao ?? 0}L
                                <br />
                                Água acumulada na mangueira: {v.aguaAcumuladaMangueira ?? 0}L
                              </div>
                              <div>
                                Pressão do óleo: {v.pressaoOleo ?? 0}
                                <br />
                                Pressão do ar: {v.pressaoAr ?? 0}
                              </div>
                              {v.alertas && v.alertas.length > 0 && <div>Alertas: {v.alertas ?? 0}</div>}
                            </S.LocationMarkerTooltipBody>
                            <S.LocationMarkerTooltipCloseButton onClick={(e) => handleCloseClick(e)}>
                              <CloseOutlined />
                            </S.LocationMarkerTooltipCloseButton>
                          </S.LocationMarkerTooltip>
                        )}
                      </>
                    </OverlayView>
                  </>
                ))}

              {tripReport.dadosGps && (
                <OverlayView
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  position={{
                    lat: tripReport.dadosGps.ultimoPontoAntesDaEmissaoDaNF
                      ? tripReport.dadosGps.ultimoPontoAntesDaEmissaoDaNF[0]
                      : 0,
                    lng: tripReport.dadosGps.ultimoPontoAntesDaEmissaoDaNF
                      ? tripReport.dadosGps.ultimoPontoAntesDaEmissaoDaNF[1]
                      : 0,
                  }}
                >
                  <S.ConcreteMixerMarker>
                    <IconCaminhaoBetoneiraEsquerda />
                  </S.ConcreteMixerMarker>
                </OverlayView>
              )}

              {!tripReport?.dadosRemessa?.obraCoordenadas || tripReport?.dadosRemessa?.obraCoordenadas.length <= 0 ? (
                <OverlayView
                  position={{
                    lat: tripReport?.dadosRemessa?.obraLatitude ?? supermixLocation.lat,
                    lng: tripReport?.dadosRemessa?.obraLongitude ?? supermixLocation.lng,
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <>
                    <S.PinMarker>
                      <img src={BalaoBetoneiraIcon} alt="" />
                    </S.PinMarker>
                    <Circle
                      center={{
                        lat: tripReport?.dadosRemessa?.obraLatitude ?? supermixLocation.lat,
                        lng: tripReport?.dadosRemessa?.obraLongitude ?? supermixLocation.lng,
                      }}
                      radius={tripReport?.dadosRemessa?.obraRaio ?? supermixLocation.raio}
                      options={{
                        fillColor: '#861412',
                        fillOpacity: 0.3,
                        strokeColor: '#861412',
                        strokeOpacity: 1,
                        strokeWeight: 2,
                      }}
                    />
                  </>
                </OverlayView>
              ) : (
                <OverlayView
                  position={{
                    lat: tripReport.dadosRemessa.obraCoordenadasCentro
                      ? tripReport.dadosRemessa.obraCoordenadasCentro[0] ?? 0
                      : 0,
                    lng: tripReport.dadosRemessa.obraCoordenadasCentro
                      ? tripReport.dadosRemessa.obraCoordenadasCentro[1] ?? 0
                      : 0,
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <>
                    <S.PinMarker>
                      <img src={BalaoBetoneiraIcon} alt="" />
                    </S.PinMarker>
                    <Polygon
                      paths={
                        tripReport.dadosRemessa.obraCoordenadas
                          ? tripReport.dadosRemessa.obraCoordenadas?.map((c) => ({
                              lat: c.latitude ?? 0,
                              lng: c.longitude ?? 0,
                            }))
                          : []
                      }
                      options={{
                        fillColor: '#861412',
                        fillOpacity: 0.3,
                        strokeColor: '#861412',
                        strokeOpacity: 1,
                        strokeWeight: 2,
                      }}
                    />
                  </>
                </OverlayView>
              )}

              <OverlayView
                position={{
                  lat: tripReport?.dadosRemessa?.filialLatitude ?? supermixLocation.lat,
                  lng: tripReport?.dadosRemessa?.filialLongitude ?? supermixLocation.lng,
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <>
                  <S.PinMarker>
                    <img src={HomeIcon} alt="" />
                  </S.PinMarker>
                  <Circle
                    center={{
                      lat: tripReport?.dadosRemessa?.filialLatitude ?? supermixLocation.lat,
                      lng: tripReport?.dadosRemessa?.filialLongitude ?? supermixLocation.lng,
                    }}
                    radius={tripReport?.dadosRemessa?.filialRaio ?? supermixLocation.raio}
                    options={{
                      fillColor: '#861412',
                      fillOpacity: 0.1,
                      strokeColor: '#861412',
                      strokeOpacity: 1,
                      strokeWeight: 2,
                    }}
                  />
                </>
              </OverlayView>
            </GoogleMap>
          )}
        </>
      )}
    </div>
  );
};

export default TripAnalyticDashboard;
