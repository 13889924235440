import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import Dashboard from '@app/components/dashboard/Dashboard';
import { notificationController } from '@app/controllers/notificationController';
import {
  ReportTripErrorAnalytic,
  ReportTripErrorItemAnalytic,
} from '@app/domain/interfaces/report/reportTripErrorAnalytic';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IReportService, { ReportService } from '@app/services/reportService';
import { setHeader } from '@app/store/slices/headerSlice';
import { joinArrayToString, minutesToHoursAndMinutes } from '@app/utils/utils';
import { Badge, Col, DatePicker, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC, useCallback, useEffect, useState } from 'react';
import { exportReportTripErrorAnalyticToExcel } from './functions/excelFunctions';
import { ColumnFilterItem, ExpandableConfig } from 'antd/lib/table/interface';
import { IconDown, IconMore, IconUp } from '@app/assets/slump-icons';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { Tag } from '@app/components/common/Tag/Tag';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Button } from '@app/components/common/buttons/Button/Button';
import moment from 'moment';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { SearchOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;

const reportService: IReportService = new ReportService();

export const TripErrorAnalyticDashboard: FC = () => {
  const dispatch = useAppDispatch();
  const [period, setPeriod] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [customRangeDate, setCustomRangeDate] = useState<(moment.Moment | null)[]>([]);
  const [report, setReport] = useState<ReportTripErrorAnalytic>();
  const [tripsFiltered, setTripsFiltered] = useState<ReportTripErrorItemAnalytic[]>();
  const [errorFilters, setErrorFilters] = useState<ColumnFilterItem[]>([]);

  const fetch = useCallback(() => {
    let startDate = moment().subtract(8, 'days');
    let endDate = moment().subtract(1, 'days');

    if (period == 2) {
      if (!customRangeDate[0] || !customRangeDate[1]) {
        notificationController.error({ message: 'Erro! Selecione o período.' });
        return;
      }

      startDate = customRangeDate[0];
      endDate = customRangeDate[1];
    }

    setLoading(true);

    reportService
      .getTripReportErrorAnalytic(startDate.toDate(), endDate.toDate())
      .then((res) => {
        setReport(res);
        setTripsFiltered(res.viagens);
        setErrorFilters(
          Array.from(new Set(res.viagens.flatMap((item) => item.erros))).map((error) => ({
            text: error,
            value: error,
          })),
        );
      })
      .catch((error) => notificationController.error(error))
      .finally(() => setLoading(false));
  }, [period, customRangeDate]);

  const disableDate = (current: moment.Moment) => current > moment().subtract(1, 'days').endOf('day');
  const handleCalendarChange = (dates: [moment.Moment | null, moment.Moment | null]) => {
    if (dates && dates[0] && dates[1]) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (endDate.diff(startDate, 'days') > 6) {
        setCustomRangeDate([startDate, startDate.clone().add(6, 'days')]);
      } else {
        setCustomRangeDate(dates);
      }
    } else {
      setCustomRangeDate(dates);
    }
  };
  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setTripsFiltered(
        report?.viagens?.filter(
          (item) =>
            item.idViagem?.toString().toLowerCase().includes(inputValue) ||
            item.deviceId?.toString().toLowerCase().includes(inputValue) ||
            item.betoneira?.toString().toLowerCase().includes(inputValue) ||
            item.statusErro?.toString().toLowerCase().includes(inputValue) ||
            minutesToHoursAndMinutes(item.tempoAguardoInicioViagem).toLowerCase().includes(inputValue) ||
            joinArrayToString(item.estados).toLowerCase().includes(inputValue) ||
            (!item.semResumo ? 'Sim' : 'Não').toLowerCase().includes(inputValue) ||
            (item.tempoAguardoInicioViagem > 30 ? 'Sim' : 'Não').toLowerCase().includes(inputValue),
        ) ?? [],
      );
    } else {
      setTripsFiltered(report?.viagens ?? []);
    }
  };
  const handleExportToExcel = useCallback(() => {
    tripsFiltered && exportReportTripErrorAnalyticToExcel(tripsFiltered);
  }, [tripsFiltered]);

  const columns: ColumnsType<ReportTripErrorItemAnalytic> = [
    {
      title: 'Id Viagem',
      dataIndex: 'idViagem',
      showSorterTooltip: false,
      sorter: (a, b) => (a?.idViagem ? a.idViagem : 0) - (b?.idViagem ? b.idViagem : 0),
    },
    {
      title: 'Nº NF',
      dataIndex: 'codigoNotaFiscal',
      showSorterTooltip: false,
      sorter: (a, b) => (a?.codigoNotaFiscal ? a.codigoNotaFiscal : 0) - (b?.codigoNotaFiscal ? b.codigoNotaFiscal : 0),
    },
    {
      title: 'Data NF',
      dataIndex: 'dataEmissaoNotaFiscal',
      showSorterTooltip: false,
      sorter: (a, b) =>
        (a?.dataEmissaoNotaFiscal ? new Date(a.dataEmissaoNotaFiscal).getTime() : 0) -
        (b?.dataEmissaoNotaFiscal ? new Date(b.dataEmissaoNotaFiscal).getTime() : 0),
      render: (dataHora: string) => (dataHora ? moment(dataHora).format('DD/MM/YYYY HH:mm') + 'h' : '-'),
    },
    {
      title: 'DeviceId',
      dataIndex: 'deviceId',
      showSorterTooltip: false,
      sorter: (a, b) => a.deviceId?.localeCompare(b.deviceId),
    },
    {
      title: 'Betoneira',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      sorter: (a, b) => a.betoneira?.localeCompare(b.betoneira),
    },
    {
      title: 'Tipo Erro',
      dataIndex: 'statusErro',
      showSorterTooltip: false,
      filters: [
        { text: 'Aviso', value: 'Aviso' },
        { text: 'Erro', value: 'Erro' },
      ],
      onFilter: (value, record) => record.statusErro == value,
      sorter: (a, b) => a.statusErro?.localeCompare(b.statusErro),
    },
    {
      title: 'Tempo Início Viagem',
      dataIndex: 'tempoAguardoInicioViagem',
      showSorterTooltip: false,
      sorter: (a, b) =>
        (a?.tempoAguardoInicioViagem ? a.tempoAguardoInicioViagem : 0) -
        (b?.tempoAguardoInicioViagem ? b.tempoAguardoInicioViagem : 0),
      render: (tempoAguardoInicioViagem: number) => minutesToHoursAndMinutes(tempoAguardoInicioViagem),
    },
    {
      title: 'Resumo',
      dataIndex: 'semResumo',
      showSorterTooltip: false,
      filters: [
        { text: 'Sim', value: false },
        { text: 'Não', value: true },
      ],
      onFilter: (value, record) => value === (record.semResumo ? true : false),
      render: (semResumo: boolean) =>
        !semResumo ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />,
    },
    {
      title: 'Espera excedida',
      dataIndex: 'tempoAguardoInicioViagem',
      showSorterTooltip: false,
      sorter: (a, b) =>
        (a?.tempoAguardoInicioViagem ? a.tempoAguardoInicioViagem : 0) -
        (b?.tempoAguardoInicioViagem ? b.tempoAguardoInicioViagem : 0),
      render: (tempoAguardoInicioViagem: number) =>
        tempoAguardoInicioViagem > 30 ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />,
    },
    {
      title: 'Total pontos',
      dataIndex: 'totalPontosViagem',
      showSorterTooltip: false,
      sorter: (a, b) =>
        (a?.totalPontosViagem ? a.totalPontosViagem : 0) - (b?.totalPontosViagem ? b.totalPontosViagem : 0),
    },
    {
      title: '% Backup',
      dataIndex: 'percentualBackup',
      showSorterTooltip: false,
      sorter: (a, b) => (a?.percentualBackup ? a.percentualBackup : 0) - (b?.percentualBackup ? b.percentualBackup : 0),
      render: (percentualBackup) => (percentualBackup ? `${percentualBackup?.toFixed(2)}%` : '-'),
    },
    {
      title: 'Erros',
      dataIndex: 'erros',
      filters: errorFilters,
      onFilter: (value, record) => (record.erros.includes(value as string) ? true : false),
      showSorterTooltip: false,
      render: (erros: string[]) => erros?.length,
    },
    {
      title: 'Estados',
      dataIndex: 'estados',
      showSorterTooltip: false,
      render: (estados: number[]) => (estados ? joinArrayToString(estados.toSorted()) : '-'),
    },
    {
      title: '',
      dataIndex: 'idViagem',
      showSorterTooltip: false,
      sorter: (a, b) => a.statusErro?.localeCompare(b.statusErro),
      sortDirections: [],
      render: (idViagem: number) => (
        <DropdownTable
          buttonText=""
          iconD={
            <div style={{ color: '#545454' }}>
              <IconMore />
            </div>
          }
          trigger={['click']}
          placement="bottomRight"
          overlay={
            <Menu>
              <MenuItem>
                <Button type="text" onClick={() => window.open(`/relatorio/erros-viagem/${idViagem}`, '_blank')}>
                  Visualizar erros
                </Button>
              </MenuItem>
              <MenuItem>
                <Button type="text" onClick={() => window.open(`/relatorio/analise-viagem/${idViagem}`, '_blank')}>
                  Visualizar viagem
                </Button>
              </MenuItem>
            </Menu>
          }
        />
      ),
    },
  ];
  const expandableGroup: ExpandableConfig<ReportTripErrorItemAnalytic> = {
    expandIcon: ({ expanded, onExpand, record }) => {
      return (
        <Row onClick={(e) => onExpand(record, e)} style={{ cursor: 'pointer' }}>
          {record.erros?.length ? expanded ? <IconUp /> : <IconDown /> : ''}
        </Row>
      );
    },
    expandedRowRender: (report) => (
      <Row>
        {report.erros.map((erro, i) => (
          <Col key={i}>
            <Tag bgColor="var(--secondary-color)" textColor="white">
              {erro}
            </Tag>
          </Col>
        ))}
      </Row>
    ),
  };

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Relatório analítico de erros da viagem',
      }),
    );
  }, [fetch, dispatch]);

  return (
    <>
      <Spinner spinning={loading}></Spinner>

      <BaseForm layout="vertical">
        <Row style={{ padding: '0 0 0 2.5rem', width: '100%' }} gutter={16}>
          <Col xs={24} md={4}>
            <BaseFormInputItem label="Tipo do período">
              <Select
                value={period}
                options={[
                  {
                    value: 1,
                    label: 'Últimos 7 dias (D-1)',
                  },
                  {
                    value: 2,
                    label: 'Personalizar',
                  },
                ]}
                onChange={(value) => setPeriod(value as number)}
                placeholder="Selecione o tipo do perído"
              />
            </BaseFormInputItem>
          </Col>
          {period == 2 && (
            <Col xs={24} md={4}>
              <BaseFormInputItem label="Períodos" supportText="Período máximo de 7 dias">
                <RangePicker
                  allowClear
                  disabledDate={disableDate}
                  value={[customRangeDate[0], customRangeDate[1]]}
                  onChange={(values) => handleCalendarChange(values as [moment.Moment, moment.Moment])}
                  format={'DD-MM-YYYY'}
                  clearIcon={false}
                />
              </BaseFormInputItem>
            </Col>
          )}
          <Col>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              style={{ marginTop: '2rem' }}
              onClick={() => fetch()}
              disabled={!period}
            />
          </Col>
        </Row>
      </BaseForm>

      <Dashboard
        title=""
        buttonText=""
        table={
          <Table
            rowKey="idViagem"
            bordered={true}
            columns={columns}
            dataSource={tripsFiltered}
            expandable={expandableGroup}
          />
        }
        placeholderTextSearch="Pesquisar"
        handleSearchOnChange={handleSearch}
        exportToExcel={handleExportToExcel}
      />
    </>
  );
};
