import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { notificationController } from '@app/controllers/notificationController';
import {
  ReportTripErrorDetailAlertAnalytic,
  ReportTripErrorDetailAnalytic,
  ReportTripErrorDetailStateAnalytic,
} from '@app/domain/interfaces/report/reportTripErrorDetailAnalytic';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IReportService, { ReportService } from '@app/services/reportService';
import { setHeader } from '@app/store/slices/headerSlice';
import { Col, Row } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Badge } from '@app/components/common/Badge/Badge';
import { minutesToHoursAndMinutes, renderViagemEstadoTexto } from '@app/utils/utils';
import moment from 'moment';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';

const reportService: IReportService = new ReportService();

export const TripErrorAnalyticView: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<ReportTripErrorDetailAnalytic>();

  const fetch = useCallback((id: string) => {
    setLoading(true);

    reportService
      .getTripReportErrorAnalyticByIdTrip(id)
      .then((res) => {
        setReport(res);
      })
      .catch((error) => notificationController.error(error))
      .finally(() => setLoading(false));
  }, []);

  const columnsAlerts: ColumnsType<ReportTripErrorDetailAlertAnalytic> = [
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      sorter: (a, b) => (a?.dataHora ? a.dataHora.getTime() : 0) - (b?.dataHora ? b.dataHora.getTime() : 0),
      render: (dataHora: string) => (dataHora ? moment(dataHora).format('DD/MM/YYYY HH:mm') + 'h' : '-'),
    },
    {
      title: 'Título',
      dataIndex: 'logAlarmes',
      showSorterTooltip: false,
      render: (logAlarmes) => (logAlarmes.length > 0 ? logAlarmes[0].titulo : ''),
      sorter: (a, b) =>
        a.logAlarmes?.[0]?.titulo && b.logAlarmes?.[0].titulo
          ? a.logAlarmes[0].titulo.localeCompare(b.logAlarmes[0].titulo)
          : 0,
      ellipsis: true,
    },
    {
      title: 'Categoria',
      dataIndex: 'logAlarmes',
      showSorterTooltip: false,
      render: (logAlarmes) => (logAlarmes.length > 0 ? logAlarmes[0].categoria : ''),
      sorter: (a, b) =>
        a.logAlarmes?.[0].categoria && b.logAlarmes?.[0].categoria
          ? a.logAlarmes[0].categoria.localeCompare(b.logAlarmes[0].categoria)
          : 0,
      ellipsis: true,
    },
  ];
  const columnsStates: ColumnsType<ReportTripErrorDetailStateAnalytic> = [
    {
      title: 'Id estado',
      dataIndex: 'estadoViagemAtual',
      showSorterTooltip: false,
      sorter: (a, b) =>
        (a?.estadoViagemAtual ? a.estadoViagemAtual : 0) - (b?.estadoViagemAtual ? b.estadoViagemAtual : 0),
    },
    {
      title: 'Estado',
      dataIndex: 'estadoViagemAtual',
      showSorterTooltip: false,
      sortDirections: [],
      render: (estadoViagemAtual: number) => renderViagemEstadoTexto(estadoViagemAtual),
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      sorter: (a, b) => (a?.dataHora ? a.dataHora.getTime() : 0) - (b?.dataHora ? b.dataHora.getTime() : 0),
      render: (dataHora: string) => (dataHora ? moment(dataHora).format('DD/MM/YYYY HH:mm') + 'h' : '-'),
    },
  ];

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Relatório de análise de erros da viagem',
      }),
    );

    if (id) fetch(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Spinner spinning={loading}></Spinner>
      <PageContainer>
        <CardInfoContainer>
          <Row>
            <CardInfoTitle>Informações da viagem</CardInfoTitle>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={4}>
              <CardInfoProperty>Id Viagem</CardInfoProperty>
              <CardInfoValue>{report?.idViagem ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>DataHora NF</CardInfoProperty>
              <CardInfoValue>
                {report?.dataEmissaoNotaFiscal
                  ? moment(report?.dataEmissaoNotaFiscal).format('DD/MM/YYYY HH:mm') + 'h'
                  : '-'}
              </CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Número NF</CardInfoProperty>
              <CardInfoValue>{report?.codigoNotaFiscal ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Betoneira</CardInfoProperty>
              <CardInfoValue>{report?.betoneira ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Tempo início viagem</CardInfoProperty>
              <CardInfoValue>{minutesToHoursAndMinutes(report?.tempoAguardoInicioViagem)}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Tempo total viagem</CardInfoProperty>
              <CardInfoValue>{minutesToHoursAndMinutes(report?.tempoTotalViagem)}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Água retida</CardInfoProperty>
              <CardInfoValue>{report?.aguaRetida ? `${report?.aguaRetida.toFixed(2)}L` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Água central</CardInfoProperty>
              <CardInfoValue>{report?.aguaCentral ? `${report?.aguaCentral.toFixed(2)}L` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Água obra</CardInfoProperty>
              <CardInfoValue>{report?.aguaObra ? `${report?.aguaObra.toFixed(2)}L` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Água lavagem</CardInfoProperty>
              <CardInfoValue>{report?.aguaLavagem ? `${report?.aguaLavagem.toFixed(2)}L` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Água sobra</CardInfoProperty>
              <CardInfoValue>{report?.aguaSobra ? `${report?.aguaSobra.toFixed(2)}L` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Resumo?</CardInfoProperty>
              <CardInfoValue>
                {!report?.semResumo ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />}
              </CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Pressão óleo (min)</CardInfoProperty>
              <CardInfoValue>{report?.sensorPressaoOleoMin ? `${report?.sensorPressaoOleoMin}` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Pressão óleo (max)</CardInfoProperty>
              <CardInfoValue>{report?.sensorPressaoOleoMax ? `${report?.sensorPressaoOleoMax}` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Pressão ar (min)</CardInfoProperty>
              <CardInfoValue>{report?.sensorPressaoArMin ? `${report?.sensorPressaoArMin}` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Pressão ar (max)</CardInfoProperty>
              <CardInfoValue>{report?.sensorPressaoArMax ? `${report?.sensorPressaoArMax}` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}></Col>
            <Col xs={24} md={4}></Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Rpm (min)</CardInfoProperty>
              <CardInfoValue>{report?.rpmMin ? `${report?.rpmMin}` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Rpm (max)</CardInfoProperty>
              <CardInfoValue>{report?.rpmMax ? `${report?.rpmMax}` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Acelerômetro (min)</CardInfoProperty>
              <CardInfoValue>{report?.acelerometroMin ? `${report?.acelerometroMin}` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Acelerômetro (max)</CardInfoProperty>
              <CardInfoValue>{report?.acelerometroMax ? `${report?.acelerometroMax}` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}></Col>
            <Col xs={24} md={4}></Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Latitude (min)</CardInfoProperty>
              <CardInfoValue>{report?.latMin ? `${report?.latMin}` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Latitude (max)</CardInfoProperty>
              <CardInfoValue>{report?.latMax ? `${report?.latMax}` : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Primeira localização</CardInfoProperty>
              <CardInfoValue>
                {report?.primeiraLocalizacaoViagem
                  ? `${report?.primeiraLocalizacaoViagem[0]}, ${report?.primeiraLocalizacaoViagem[1]}`
                  : '-'}
              </CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Última localização</CardInfoProperty>
              <CardInfoValue>
                {report?.ultimaLocalizacaoViagem
                  ? `${report?.ultimaLocalizacaoViagem[0]}, ${report?.ultimaLocalizacaoViagem[1]}`
                  : '-'}
              </CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Freio estac. usado?</CardInfoProperty>
              <CardInfoValue>
                {report?.freioEstacionamentoVariado ? (
                  <Badge color="green" text="Sim" />
                ) : (
                  <Badge color="red" text="Não" />
                )}
              </CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>% Backup</CardInfoProperty>
              <CardInfoValue>{report?.percentualBackup ? `${report?.percentualBackup}%` : '-'}</CardInfoValue>
            </Col>
          </Row>
        </CardInfoContainer>

        <Row style={{ paddingTop: '2rem', width: '100%' }}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <CardInfoTitle>Alertas</CardInfoTitle>
              </Col>
              <Col span={24}>
                <Table
                  style={{ paddingLeft: '0' }}
                  bordered
                  columns={columnsAlerts}
                  dataSource={report?.alertas}
                  pagination={{ pageSize: 5 }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <CardInfoTitle>Estados</CardInfoTitle>
              </Col>
              <Col span={24}>
                <Table
                  style={{ paddingLeft: '0' }}
                  bordered
                  columns={columnsStates}
                  dataSource={report?.estados}
                  pagination={{ pageSize: 5 }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};
