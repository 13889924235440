import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { ResumeDataModel } from '@app/domain/interfaces/report/reportTripAnalyticModel';
import * as S from '../styles';
import { Col, Row } from 'antd';
import moment from 'moment';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';

interface IModalTripGraphicProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  tripResume: ResumeDataModel;
}

const ModalTripResume: React.FC<IModalTripGraphicProps> = ({ visible, setVisible, tripResume }) => {
  return (
    <>
      <Modal
        open={visible}
        onCancel={() => setVisible(!visible)}
        size="medium"
        style={{ display: 'block', minWidth: '90vw' }}
        footer={null}
        title="Resumo da viagem"
      >
        <BaseForm
          style={{
            width: '100%',
          }}
        >
          <S.ResumeContainer>
            {tripResume && (
              <>
                <Row gutter={24} justify={'start'} style={{ marginBottom: '1rem' }}>
                  <S.InfoTitle>Resumo</S.InfoTitle>
                </Row>
                <Row gutter={24} justify={'start'}>
                  <S.StyledCol xs={24} md={12} lg={4} xxl={4}>
                    <S.ResumeItensTitle>Água Utilizada</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripResume.aguaRetida ?? '-'}</S.ResumeItensContent>
                  </S.StyledCol>
                  <S.StyledCol xs={24} md={12} lg={4} xxl={4}>
                    <S.ResumeItensTitle>Dif. Água</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripResume.difAgua ?? '-'}</S.ResumeItensContent>
                  </S.StyledCol>
                  <S.StyledCol xs={24} md={12} lg={4} xxl={4}>
                    <S.ResumeItensTitle>Água retida</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripResume.aguaRetida}</S.ResumeItensContent>
                  </S.StyledCol>
                  <S.StyledCol xs={24} md={12} lg={4} xxl={4}>
                    <S.ResumeItensTitle>Tempo inspeção central</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripResume.tempoInspecaoCentral ?? '-'}</S.ResumeItensContent>
                  </S.StyledCol>
                  <S.StyledCol xs={24} md={12} lg={4} xxl={4}>
                    <S.ResumeItensTitle>Qtd. Inversão Balão</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripResume.qtdInversaoBalao}</S.ResumeItensContent>
                  </S.StyledCol>
                </Row>

                <Row gutter={24} justify={'start'}>
                  <S.StyledCol xs={24} md={12} lg={4} xxl={4}>
                    <S.ResumeItensTitle>Versão Tablet</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripResume.versaoTablet ?? '-'}</S.ResumeItensContent>
                  </S.StyledCol>
                  <S.StyledCol xs={24} md={12} lg={4} xxl={4}>
                    <S.ResumeItensTitle>Versão hidrômetro</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripResume.versaoHidrometro ?? '-'}</S.ResumeItensContent>
                  </S.StyledCol>
                  <S.StyledCol xs={24} md={12} lg={4} xxl={4}>
                    <S.ResumeItensTitle>Versão central</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripResume.versaoCentral ?? '-'}</S.ResumeItensContent>
                  </S.StyledCol>
                  <S.StyledCol xs={24} md={12} lg={4} xxl={4}>
                    <S.ResumeItensTitle>Consumo combustível por estado</S.ResumeItensTitle>
                    <S.ResumeItensContent>
                      {tripResume.consumoCombustivelPorEstado &&
                        tripResume.consumoCombustivelPorEstado?.map((c, index) => (
                          <span key={c}>
                            {c}
                            {tripResume.consumoCombustivelPorEstado &&
                            index === tripResume.consumoCombustivelPorEstado?.length - 1
                              ? ''
                              : ', '}
                          </span>
                        ))}
                    </S.ResumeItensContent>
                  </S.StyledCol>
                  <S.StyledCol xs={24} md={12} lg={4} xxl={4}>
                    <S.ResumeItensTitle>Qtd. Freio Estacionamento</S.ResumeItensTitle>
                    <S.ResumeItensContent>{tripResume.qtdFreioEstacionamento}</S.ResumeItensContent>
                  </S.StyledCol>
                </Row>
                <br />
                <hr />
                <br />

                <Row gutter={24} justify={'start'}>
                  <S.InfoContainer>
                    <S.InfoTitle>Águas</S.InfoTitle>
                    <S.InfoContainerContent>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Central - Mangueira</S.ResumeItensTitle>
                        <S.ResumeItensContent>{tripResume.aguas?.central?.mangueira ?? '-'} L</S.ResumeItensContent>
                      </S.StyledCol>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Central - Balão</S.ResumeItensTitle>
                        <S.ResumeItensContent>{tripResume.aguas?.central?.balao ?? '-'} L</S.ResumeItensContent>
                      </S.StyledCol>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Obra - Mangueira</S.ResumeItensTitle>
                        <S.ResumeItensContent>{tripResume.aguas?.obra?.mangueira ?? '-'} L</S.ResumeItensContent>
                      </S.StyledCol>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Obra - Balão</S.ResumeItensTitle>
                        <S.ResumeItensContent>{tripResume.aguas?.obra?.balao ?? '-'} L</S.ResumeItensContent>
                      </S.StyledCol>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Lavagem - Mangueira</S.ResumeItensTitle>
                        <S.ResumeItensContent>{tripResume.aguas?.lavagem?.mangueira ?? '-'} L</S.ResumeItensContent>
                      </S.StyledCol>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Lavagem - Balão</S.ResumeItensTitle>
                        <S.ResumeItensContent>{tripResume.aguas?.lavagem?.balao ?? '-'} L</S.ResumeItensContent>
                      </S.StyledCol>
                    </S.InfoContainerContent>
                  </S.InfoContainer>
                </Row>

                <br />
                <hr />
                <br />

                <Row gutter={24} justify={'start'}>
                  <S.InfoContainer>
                    <S.InfoTitle>Tempos</S.InfoTitle>
                    <S.InfoContainerContent>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Estado Um</S.ResumeItensTitle>
                        <S.ResumeItensContent>
                          {tripResume.tempos?.estadoUm?.toFixed(2) ?? '-'} mins
                        </S.ResumeItensContent>
                      </S.StyledCol>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Estado Dois</S.ResumeItensTitle>
                        <S.ResumeItensContent>
                          {tripResume.tempos?.estadoDois?.toFixed(2) ?? '-'} mins
                        </S.ResumeItensContent>
                      </S.StyledCol>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Estado Tres</S.ResumeItensTitle>
                        <S.ResumeItensContent>
                          {tripResume.tempos?.estadoTres?.toFixed(2) ?? '-'} mins
                        </S.ResumeItensContent>
                      </S.StyledCol>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Estado Quatro</S.ResumeItensTitle>
                        <S.ResumeItensContent>
                          {tripResume.tempos?.estadoQuatro?.toFixed(2) ?? '-'} mins
                        </S.ResumeItensContent>
                      </S.StyledCol>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Estado Cinco</S.ResumeItensTitle>
                        <S.ResumeItensContent>
                          {tripResume.tempos?.estadoCinco?.toFixed(2) ?? '-'} mins
                        </S.ResumeItensContent>
                      </S.StyledCol>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Estado Seis</S.ResumeItensTitle>
                        <S.ResumeItensContent>
                          {tripResume.tempos?.estadoSeis?.toFixed(2) ?? '-'} mins
                        </S.ResumeItensContent>
                      </S.StyledCol>
                      <S.StyledCol xs={24} md={12} lg={3} xxl={3}>
                        <S.ResumeItensTitle>Estado Sete</S.ResumeItensTitle>
                        <S.ResumeItensContent>
                          {tripResume.tempos?.estadoSete?.toFixed(2) ?? '-'} mins
                        </S.ResumeItensContent>
                      </S.StyledCol>
                    </S.InfoContainerContent>
                  </S.InfoContainer>
                </Row>
              </>
            )}
          </S.ResumeContainer>
        </BaseForm>
      </Modal>
    </>
  );
};

export default ModalTripResume;
