import React from 'react';
import ApexCharts from 'apexcharts';
import { useEffect } from 'react';
import moment from 'moment';
import {
  formatSecondsToHoursAndMinutesReports,
  getDifferenceBetweenDatesInHours,
  getDifferenceBetweenDatesInMinutes,
  renderViagemEstadoTexto,
} from '@app/utils/utils';
import { IChartReport, ITooltipConfig } from './interface/chartReport';
import MouseTracker from '@app/components/common/MouseTracker/MouseTracker';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import { getTooltipConfig } from '@app/pages/modules/Reports/Charts/getTooltipConfig';

interface ITooltipConfigExtended extends ITooltipConfig {
  color?: string;
}

export const ChartMixerSpeed: React.FC<IChartReport> = ({ travelDetails }) => {
  const [tooltipConfig, setTooltipConfig] = React.useState<ITooltipConfigExtended>({
    visible: false,
    title: '',
    value: '',
    color: '#374450',
  });

  useEffect(() => {
    if (travelDetails.velocidadeBetoneira?.length > 0) {
      const options = {
        chart: {
          id: 'water',
          type: 'line',
          group: 'chart',
          width: '100%',
          height: 600,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '14px',
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontStyle: 'normal',
          markers: {
            width: 24,
            height: 5,
            radius: 0,
          },
        },
        tooltip: {
          style: {
            fontFamily: 'Mulish',
          },
        },
        stroke: {
          curve: 'smooth',
          width: [2, 2, 8],
        },
        colors: ['#C41B24', '#374450'],
        series: [
          {
            type: 'line',
            name: 'Linha 1 - Velocidade (km/h)',
            data: travelDetails.velocidadeBetoneira
              ?.filter((u) => u.dataHora !== undefined)
              ?.map((u) => ({ y: u.velocidadeBetoneira, x: u.dataHora ? new Date(u.dataHora).getTime() : 0 }))
              .sort((a, b) => b.x - a.x),
          },
          // {
          //   type: 'line',
          //   name: 'Tempos da viagem',
          //   data: travelDetails.temposViagemConsumo
          //     ?.map((u) => ({ y: 0, x: u.dataHora ? new Date(u.dataHora).getTime() : 0 }))
          //     .sort((a, b) => b.x - a.x),
          // },
        ],
        yaxis: {
          labels: {
            style: {
              fontSize: '16px',
              fontFamily: 'Mulish',
              fontWeight: 600,
            },
            formatter: (value: number) => value?.toFixed(1) + ' km/h',
          },
        },
        xaxis: {
          min: new Date(travelDetails.estadosViagem[1]?.dataHora ?? 0).getTime(),
          max: new Date(travelDetails.estadosViagem[travelDetails.estadosViagem.length - 1]?.dataHora ?? 0).getTime(),
          type: 'datetime',
          tickAmount: 15,
          labels: {
            datetimeUTC: true,
            formatter: (value: number | number[]) =>
              typeof value == 'number' ? moment(value).format('HH:mm') : moment(value[0]).format('HH:mm'),
          },
          tooltip: {
            enabled: false,
          },
        },
        annotations: {
          xaxis: [
            ...travelDetails.estadosViagem
              ?.filter((estadoViagem) => estadoViagem.estadoViagemAtual && estadoViagem.estadoViagemAtual > 1)
              .map((estado, index) => {
                return {
                  x: estado.dataHora ? new Date(estado.dataHora).getTime() : 0,
                  strokeDashArray: 0,
                  borderColor: '#5EB576',
                  label: {
                    position:
                      index < travelDetails.alertas.length - 2 &&
                      estado.estadoViagemAtual !== 1 &&
                      getDifferenceBetweenDatesInMinutes(
                        new Date(estado.dataHora ?? 0),
                        new Date(travelDetails.alertas[index + 2]?.dataHora ?? 0),
                      ) <= 5 &&
                      getDifferenceBetweenDatesInHours(
                        new Date(estado.dataHora ?? 0),
                        new Date(travelDetails.alertas[index + 2]?.dataHora ?? 0),
                      ) < 1
                        ? 'top'
                        : 'top',
                    orientation: 'vertical',
                    style: {
                      color: '#707070',
                      background: '#fff',
                      fontSize: '16px',
                      offsetX: 0,
                      offsetY: -30,
                      padding: {
                        left: 20,
                        right: 10,
                        top: 5,
                        bottom: 5,
                      },
                    },
                    text: renderViagemEstadoTexto(estado.estadoViagemAtual ?? 0),
                  },
                };
              }),
          ],
          yaxis: [
            {
              y: 70,
              borderColor: '#A71916',
              dashArray: 0,
              strokeDashArray: 1,
              label: {
                borderColor: '#00E396',
                style: {
                  color: '#fff',
                  background: '#00E396',
                },
                text: 'Y Axis Annotation',
              },
            },
          ],
          points: travelDetails.temposViagemConsumo
            ?.map((tempoViagem) => {
              return {
                x: tempoViagem.dataHoraEntreEstados ? new Date(tempoViagem.dataHoraEntreEstados).getTime() : 0,
                y: 0,
                marker: {
                  size: 9,
                  shape: 'square',
                  fillColor: '#374450',
                  strokeColor: '#374450',
                  tooltip: {
                    enabled: true,
                  },
                },
                mouseEnter: () => {
                  setTooltipConfig(getTooltipConfig(tempoViagem));
                },
                mouseLeave: () => {
                  setTooltipConfig({
                    title: '',
                    value: '',
                    visible: false,
                  });
                },
              };
            })
            .concat(
              travelDetails.velocidadeExcedida.map((velocidadeExcedida) => {
                return {
                  x: velocidadeExcedida.dataHoraMarcacao ? new Date(velocidadeExcedida.dataHoraMarcacao).getTime() : 0,
                  y: 70,
                  marker: {
                    size: 9,
                    shape: 'square',
                    fillColor: '#C41B24',
                    strokeColor: '#C41B24',
                    tooltip: {
                      enabled: true,
                    },
                  },
                  mouseEnter: () => {
                    setTooltipConfig({
                      title: 'Tempo em velocidade excedida',
                      value: `${formatSecondsToHoursAndMinutesReports(velocidadeExcedida.tempoExcedidoSegundos ?? 0)}`,
                      visible: true,
                      color: '#C41B24',
                    });
                  },
                  mouseLeave: () => {
                    setTooltipConfig({
                      title: '',
                      value: '',
                      visible: false,
                    });
                  },
                };
              }),
            ),
        },
      };
      const chartMixerSpeed = new ApexCharts(document.querySelector('#chart_mixer_speed'), options);
      chartMixerSpeed.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!(travelDetails.velocidadeBetoneira?.length > 0) && (
        <h3 style={{ paddingLeft: '1rem' }}>Nenhum dado disponível para exibição.</h3>
      )}
      {tooltipConfig.visible && (
        <MouseTracker offset={{ x: 0, y: 0 }}>
          <div className="tooltip">
            <span className="square" style={{ backgroundColor: tooltipConfig.color ?? '#374450' }}></span>
            <span className="text-label">{tooltipConfig.title}</span>
            <span className="text-value">{tooltipConfig.value}</span>
          </div>
        </MouseTracker>
      )}
      {travelDetails.velocidadeExcedida.length > 0 && (
        <HelperNotification
          style={{
            color: '#3f0f09',
            backgroundColor: '#fceaea',
            border: '1px solid #A71916',
          }}
          iconColor={'#A71916'}
        >
          Nesta viagem tivemos {travelDetails.velocidadeExcedida.length} alertas de limite de velocidade ultrapassado.
        </HelperNotification>
      )}
      <div id="chart_mixer_speed"></div>
    </>
  );
};
