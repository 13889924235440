import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Tooltip } from '@app/components/common/Tooltip/Tooltip';
import Dashboard from '@app/components/dashboard/Dashboard';
import { notificationController } from '@app/controllers/notificationController';
import { ShippingListModel } from '@app/domain/interfaces/report/reportShippingListModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IReportService, { ReportService } from '@app/services/reportService';
import { setHeader } from '@app/store/slices/headerSlice';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const reportService: IReportService = new ReportService();

export const ShippingListAnalyticDashboard: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<ShippingListModel[]>();
  const [reportFiltered, setReportFiltered] = useState<ShippingListModel[]>();

  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setReportFiltered(
        report?.filter(
          (item) =>
            item.numeroNotaFiscal?.toString().toLowerCase().includes(inputValue) ||
            item.idViagem?.toString().toLowerCase().includes(inputValue) ||
            item.betoneira?.toString().toLowerCase().includes(inputValue) ||
            item.motorista?.toString().toLowerCase().includes(inputValue) ||
            item.dataEmissaoNotaFiscal?.toString().toLowerCase().includes(inputValue) ||
            item.dataHora?.toString().toLowerCase().includes(inputValue) ||
            (item.distanciaFilialObra ? 'Sim' : 'Não').toLowerCase().includes(inputValue) ||
            (item.pontoDeCarga ? 'Sim' : 'Não').toLowerCase().includes(inputValue) ||
            item.obra?.toString().toLowerCase().includes(inputValue) ||
            item.filial?.toString().toLowerCase().includes(inputValue),
        ) ?? [],
      );
    } else {
      setReportFiltered(report ?? []);
    }
  };
  const fetchReportData = useCallback(() => {
    setLoading(true);

    const dataAtual = new Date();

    reportService
      .getShippingListReport(
        new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate() - 2),
        dataAtual,
      )
      .then((res) => {
        setReport(res);
        setReportFiltered(res);
      })
      .catch((error) => notificationController.error(error))
      .finally(() => setLoading(false));
  }, []);

  const columns: ColumnsType<ShippingListModel> = [
    {
      title: 'Viagem',
      dataIndex: 'idViagem',
      showSorterTooltip: false,
      sorter: (a, b) => a.idViagem!.localeCompare(b.idViagem!),
    },
    {
      title: 'NF',
      dataIndex: 'numeroNotaFiscal',
      showSorterTooltip: false,
      sorter: (a, b) => a.numeroNotaFiscal!.localeCompare(b.numeroNotaFiscal!),
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      showSorterTooltip: false,
      sorter: (a, b) => a.motorista!.localeCompare(b.motorista!),
      ellipsis: true,
    },
    {
      title: 'Betoneira',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      sorter: (a, b) => a.betoneira!.localeCompare(b.betoneira!),
    },

    {
      title: 'Data/Hora Remessa',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      sorter: (a, b) =>
        a.dataHora && b.dataHora ? new Date(a.dataHora).getTime() - new Date(b.dataHora).getTime() : 0,
      width: '15%',
      render: (dataHora: Date) => (dataHora ? moment(dataHora).format('DD/MM/YYYY HH:mm') + 'h' : '-'),
    },

    {
      title: 'Data Emissão NF',
      dataIndex: 'dataEmissaoNotaFiscal',
      showSorterTooltip: false,
      sorter: (a, b) =>
        a.dataEmissaoNotaFiscal && b.dataEmissaoNotaFiscal
          ? new Date(a.dataEmissaoNotaFiscal).getTime() - new Date(b.dataEmissaoNotaFiscal).getTime()
          : 0,
      width: '15%',
      render: (dataEmissaoNotaFiscal: Date) =>
        dataEmissaoNotaFiscal ? moment(dataEmissaoNotaFiscal).format('DD/MM/YYYY HH:mm') + 'h' : '-',
    },
    {
      title: 'Filial',
      dataIndex: 'filial',
      showSorterTooltip: false,
      sorter: (a, b) => a.filial!.localeCompare(b.filial!),
      ellipsis: true,
    },
    {
      title: 'Obra',
      dataIndex: 'obra',
      showSorterTooltip: false,
      sorter: (a, b) => a.obra!.localeCompare(b.obra!),
      ellipsis: true,
    },
    {
      title: 'Distância Filial/Obra',
      dataIndex: 'distanciaFilialObra',
      showSorterTooltip: false,
      sorter: (a, b) => a.distanciaFilialObra!.localeCompare(b.distanciaFilialObra!),
      ellipsis: true,
    },
    {
      title: 'Ponto de carga',
      dataIndex: 'pontoDeCarga',
      showSorterTooltip: false,
      sorter: (a, b) => a.pontoDeCarga!.localeCompare(b.pontoDeCarga!),
      ellipsis: true,
    },
    {
      title: '',
      width: '4%',
      dataIndex: 'idViagem',
      showSorterTooltip: false,
      render: (idViagem) => (
        <Button type="link" size="small" onClick={() => navigate(`/relatorio/analise-viagem/${idViagem}`)}>
          <Tooltip title="Visualizar">
            <SearchOutlined />
          </Tooltip>
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchReportData();
    dispatch(
      setHeader({
        title: 'Lista de remessas',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Spinner spinning={loading}></Spinner>
      <Dashboard
        title="Lista de Remessas"
        table={<Table bordered={true} columns={columns} dataSource={reportFiltered} />}
        placeholderTextSearch="Pesquisar"
        handleSearchOnChange={handleSearch}
      />
    </>
  );
};
