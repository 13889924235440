import { AppstoreAddOutlined, ReconciliationOutlined } from '@ant-design/icons';
import { ReactComponent as AlertIcon } from '@app/assets/icons/alertIcon.svg';
import { ReactComponent as MonitoringIcon } from '@app/assets/icons/monitoringIcon.svg';
import { ReactComponent as UsersIcon } from '@app/assets/icons/usersIcon.svg';
import { ReactComponent as ScheduleIcon } from '@app/assets/icons/sync.svg';
import { ReactComponent as ReportsIcon } from '@app/assets/icons/reports.svg';
import AlertDashboard from '@app/pages/modules/Alert/AlertDashboard';
import ClientBranchDashboard from '@app/pages/modules/ClientBranch/Dashboard';
import ClientDashboard from '@app/pages/modules/Clients/ClientDashboard';
import ConfigAlertDashboard from '@app/pages/modules/ConfigAlert/Dashboard';
import FirstAccessPage from '@app/pages/FirstAccessPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import LockPage from '@app/pages/LockPage';
import LoginPage from '@app/pages/LoginPage';
import MonitoringDashboard from '@app/pages/modules/Monitoring/MonitoringDashboard';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import SignUpPage from '@app/pages/SignUpPage';
import UserDashboard from '@app/pages/modules/User/UserDashboard';
import AuthLayout from '../layouts/AuthLayout/AuthLayout';
import MainLayout from '../layouts/main/MainLayout/MainLayout';
import MonitoringLayout from '../layouts/MonitoringLayout/MonitoringLayout';
import TrackingLayout from '../layouts/TrackingLayout/TrackingLayout/TrackingLayout';
import RequireAuth from './RequireAuth';
import ForgotPasswordSucessPage from '@app/pages/ForgotPasswordSucessPage';
import Logout from './Logout';
import Error403Page from '@app/pages/Error403Page';
import { RegistrationPagesLayout } from '../layouts/RegistrationPages/RegistrationPagesLayout';
import ClientBranchCreate from '@app/pages/modules/ClientBranch/Create';
import ClientCreate from '@app/pages/modules/Clients/Create';
import ConfigAlertCreate from '@app/pages/modules/ConfigAlert/Create';
import ClientGroupDashboard from '@app/pages/modules/ClientGroup/Dashboard';
import ClientGroupCreate from '@app/pages/modules/ClientGroup/Create';
import DeviceDashboard from '@app/pages/modules/Device/DeviceDashboard';
import DeviceTwin from '@app/pages/modules/Device/DeviceTwin';
import ProfileDashboard from '@app/pages/modules/Profile/ProfileDashboard';
import ProfileCreate from '@app/pages/modules/Profile/ProfileCreate';
import UserCreate from '@app/pages/modules/User/Create';
import Error404Page from '@app/pages/Error404Page';
import Error500Page from '@app/pages/Error500Page';
import AlertJustify from '@app/pages/modules/Alert/AlertJustify';
import ScheduleDashboard from '@app/pages/modules/Schedule/ScheduleDashboard';
import ScheduleView from '@app/pages/modules/Schedule/View';
import ScheduleCreate from '@app/pages/modules/Schedule/Create';
import ReportsDashboard from '@app/pages/modules/Reports/ReportsDashboard';
import { ReportDetails } from '@app/pages/modules/Reports/ReportDetails';
import FirmwareVersionDashboard from '@app/pages/modules/FirmwareVersion/FirmwareVersionDashboard';
import FirmwareVersionCreate from '@app/pages/modules/FirmwareVersion/Create';
import ConcreteMixerCreate from '@app/pages/modules/ConcreteMixer/Create';
import ConcreteMixerDashboard from '@app/pages/modules/ConcreteMixer/Dashboard';
import DeviceCreate from '@app/pages/modules/Device/DeviceCreate';
import ConcreteMixerMonitoringDashboard from '@app/pages/modules/Dashboards/ConcreteMixerDashboard/ConcreteMixerMonitoringDashboard';
import ConcreteMixerMonitoring from '../layouts/ConcreteMixerMonitoringLayout/ConcreteMixerMonitoring';
import ConcreteMixerTracking from '@app/pages/modules/ConcreteMixer/Tracking';
import { IconCaminhaoBetoneiraEsquerda } from '@app/assets/slump-icons';
import ConcreteMixerLocation from '@app/components/layouts/ConcreteMixerLocationLayout/ConcreteMixerLocation';
import CentralTrackingDashboard from '@app/pages/modules/CentralTracking/CentralTrackingDashboard';
import { TravelDetails } from '@app/pages/modules/Reports/TravelDetails';
import { CentralTrackingPointReport } from '@app/pages/modules/CentralTracking/CentralTrackingPointReport';
import { MixerReportAnalyticDashboard } from '@app/pages/modules/ReportsAnalytic/MixerErrorsReportAnalytic';
import { MixerReportAnalyticDeviceView } from '@app/pages/modules/ReportsAnalytic/MixerErrorsReportAnalytic/view';
import TripAnalyticDashboard from '@app/pages/modules/ReportsAnalytic/TripAnalytic';
import { ShippingListAnalyticDashboard } from '@app/pages/modules/ReportsAnalytic/ShippingListAnalytic';
import { TripErrorAnalyticDashboard } from '@app/pages/modules/ReportsAnalytic/TripErrorAnalytic';
import { TripErrorAnalyticView } from '@app/pages/modules/ReportsAnalytic/TripErrorAnalytic/view';

const mainLayout = (
  <RequireAuth key="main">
    <MainLayout />
  </RequireAuth>
);

const trackingLayout = (
  <RequireAuth key="tracking">
    <TrackingLayout />
  </RequireAuth>
);

const monitoringLayout = (
  <RequireAuth key="monitoring">
    <MonitoringLayout />
  </RequireAuth>
);

const concreteMixerMonitoringLayout = (
  <RequireAuth key="monitoring">
    <ConcreteMixerMonitoring />
  </RequireAuth>
);

const concreteMixerLocationLayout = (
  <RequireAuth key="monitoring">
    <ConcreteMixerLocation />
  </RequireAuth>
);

const registerPagesLayout = (
  <RequireAuth key="registerPage">
    <RegistrationPagesLayout />
  </RequireAuth>
);

const authLayout = <AuthLayout key="auth" />;

export interface RouteSlumpMixConfig {
  key: string;
  title: string;
  isSideMenu: boolean;
  path?: string;
  roles?: string[];
  role?: string;
  icon?: React.ReactNode;
  element?: JSX.Element;
  layout: JSX.Element;
  children?: RouteSlumpMixConfig[];
}

export const RoutesSlumpMix: RouteSlumpMixConfig[] = [
  {
    title: 'Alertas',
    isSideMenu: true,
    key: 'alert',
    roles: ['alert_ope', 'alert_man', 'alert_eng', 'alert_tec'],
    path: '/',
    icon: <AlertIcon />,
    layout: mainLayout,
    element: <AlertDashboard />,
  },
  {
    title: 'Cadastros',
    isSideMenu: true,
    key: 'manager',
    layout: mainLayout,
    icon: <AppstoreAddOutlined />,
    children: [
      {
        title: 'Alertas',
        isSideMenu: true,
        key: 'config_alert',
        role: 'config_alert',
        path: '/configuracao/alertas',
        layout: mainLayout,
        element: <ConfigAlertDashboard />,
      },
      {
        title: 'Betoneiras',
        isSideMenu: true,
        key: 'mixer',
        role: 'mixer',
        path: '/gestao/betoneiras',
        layout: mainLayout,
        element: <ConcreteMixerDashboard />,
      },
      {
        key: 'client',
        isSideMenu: true,
        title: 'Concreteiras',
        path: '/gestao/concreteiras',
        role: 'client',
        layout: mainLayout,
        element: <ClientDashboard />,
      },
      {
        title: 'Dispositivos',
        isSideMenu: true,
        key: 'device',
        role: 'device',
        path: '/gestao/dispositivos',
        layout: mainLayout,
        element: <DeviceDashboard />,
      },
      {
        title: 'Filiais',
        isSideMenu: true,
        key: 'branch',
        role: 'branch',
        path: '/gestao/filiais',
        layout: mainLayout,
        element: <ClientBranchDashboard />,
      },
      {
        title: 'Grupos',
        isSideMenu: true,
        key: 'group',
        role: 'group',
        path: '/gestao/grupos',
        layout: mainLayout,
        element: <ClientGroupDashboard />,
      },
    ],
  },
  {
    title: 'Dispositivo Twin',
    isSideMenu: false,
    key: 'device-twin',
    role: 'device',
    path: '/gestao/dispositivos/:id/twin',
    layout: registerPagesLayout,
    element: <DeviceTwin />,
  },
  {
    title: 'Dispositivo Twin',
    isSideMenu: false,
    key: 'device-twin',
    role: 'device',
    path: '/gestao/dispositivos/:id/twin/:view',
    layout: registerPagesLayout,
    element: <DeviceTwin />,
  },
  {
    title: 'Concreteiras',
    isSideMenu: false,
    key: 'client-create',
    role: 'client',
    path: '/gestao/concreteiras/cadastrarCliente',
    layout: registerPagesLayout,
    element: <ClientCreate />,
  },
  {
    title: 'Concreteiras',
    isSideMenu: false,
    key: 'client-edit',
    role: 'client',
    path: '/gestao/concreteiras/editarCliente/:id',
    layout: registerPagesLayout,
    element: <ClientCreate />,
  },
  {
    title: 'Configuração de Alerta',
    isSideMenu: false,
    key: 'config_alert_create',
    role: 'config_alert_create',
    path: '/configuracao/alertas/cadastrarAlerta',
    layout: registerPagesLayout,
    element: <ConfigAlertCreate />,
  },
  {
    title: 'Configuração de Alerta',
    isSideMenu: false,
    key: 'config_alert_edit',
    role: 'config_alert',
    path: '/configuracao/alertas/editarAlerta/:id',
    layout: registerPagesLayout,
    element: <ConfigAlertCreate />,
  },
  {
    title: 'Dispositivo',
    isSideMenu: false,
    key: 'device-create',
    role: 'device',
    path: '/gestao/dispositivos/cadastrar',
    layout: registerPagesLayout,
    element: <DeviceCreate />,
  },
  {
    title: 'Dispositivo',
    isSideMenu: false,
    key: 'device-edit',
    role: 'device',
    path: '/gestao/dispositivos/:id',
    layout: registerPagesLayout,
    element: <DeviceCreate />,
  },
  {
    title: 'Filial',
    isSideMenu: false,
    key: 'branch-create',
    role: 'branch',
    path: '/gestao/filiais/edit/:id',
    layout: registerPagesLayout,
    element: <ClientBranchCreate />,
  },
  {
    title: 'Filial',
    isSideMenu: false,
    key: 'branch-edit',
    role: 'branch',
    path: '/gestao/filiais/create',
    layout: registerPagesLayout,
    element: <ClientBranchCreate />,
  },
  {
    title: 'Grupos',
    isSideMenu: false,
    key: 'group-create',
    role: 'group',
    path: '/gestao/grupos/cadastrar',
    layout: registerPagesLayout,
    element: <ClientGroupCreate />,
  },
  {
    title: 'Grupos',
    isSideMenu: false,
    key: 'group-edit',
    role: 'group',
    path: '/gestao/grupos/editar/:id',
    layout: registerPagesLayout,
    element: <ClientGroupCreate />,
  },
  {
    title: 'Usuário',
    isSideMenu: false,
    key: 'user-create',
    role: 'user',
    path: '/gestao/usuarios/cadastrar',
    layout: registerPagesLayout,
    element: <UserCreate />,
  },
  {
    title: 'Usuário',
    isSideMenu: false,
    key: 'user-edit',
    role: 'user',
    path: '/gestao/usuarios/:id',
    layout: registerPagesLayout,
    element: <UserCreate />,
  },
  {
    title: 'Betoneiras',
    isSideMenu: false,
    key: 'mixer-create',
    role: 'mixer',
    path: '/gestao/betoneiras/cadastrar',
    layout: registerPagesLayout,
    element: <ConcreteMixerCreate />,
  },
  {
    title: 'Betoneiras',
    isSideMenu: false,
    key: 'mixer-edit',
    role: 'mixer',
    path: '/gestao/betoneiras/:id',
    layout: registerPagesLayout,
    element: <ConcreteMixerCreate />,
  },

  {
    title: 'Usuários e Perfis',
    isSideMenu: true,
    key: 'user_profiles',
    icon: <UsersIcon />,
    layout: mainLayout,
    children: [
      {
        title: 'Usuários',
        isSideMenu: true,
        key: 'user',
        role: 'user',
        path: '/gestao/usuarios',
        layout: mainLayout,
        element: <UserDashboard />,
      },
      {
        title: 'Perfis',
        isSideMenu: true,
        key: 'profile',
        role: 'profile',
        path: '/gestao/perfis',
        layout: mainLayout,
        element: <ProfileDashboard />,
      },
    ],
  },
  {
    title: 'Monitoramento',
    isSideMenu: true,
    key: 'monitoring',
    icon: <MonitoringIcon />,
    layout: monitoringLayout,
    role: 'monitoring',
    path: '/monitoramento',
    element: <MonitoringDashboard />,
  },
  {
    title: 'Acompanhamento Central',
    isSideMenu: false,
    key: 'central_monitoring',
    icon: <MonitoringIcon />,
    layout: trackingLayout,
    role: 'central_monitoring',
    path: '/acompanhamento',
    element: <CentralTrackingDashboard />,
  },
  {
    title: 'Acompanhamento Central',
    isSideMenu: false,
    key: 'central_monitoring_report',
    icon: <MonitoringIcon />,
    layout: mainLayout,
    role: 'central_monitoring_report',
    path: '/acompanhamento/relatorio/:idFilial/:pontoDeCarga',
    element: <CentralTrackingPointReport />,
  },
  {
    title: 'Localizar Betoneira',
    isSideMenu: true,
    key: 'mixer_tracking',
    path: '/localizar-betoneiras',
    role: 'mixer_tracking',
    icon: (
      <div>
        <IconCaminhaoBetoneiraEsquerda />
      </div>
    ),
    layout: concreteMixerLocationLayout,
    element: <ConcreteMixerTracking />,
  },
  {
    title: 'Acompanhamento Betoneiras',
    isSideMenu: false,
    key: 'mixer_monitoring',
    role: 'mixer_monitoring',
    path: '/monitoramento/betoneiras',
    layout: concreteMixerMonitoringLayout,
    element: <ConcreteMixerMonitoringDashboard />,
  },
  {
    title: 'Perfil',
    isSideMenu: false,
    key: 'profile_create',
    role: 'profile',
    path: '/gestao/perfil',
    layout: registerPagesLayout,
    element: <ProfileCreate />,
  },
  {
    title: 'Perfil',
    isSideMenu: false,
    key: 'profile_edit',
    role: 'profile',
    path: '/gestao/perfil/:id',
    layout: registerPagesLayout,
    element: <ProfileCreate />,
  },
  {
    title: 'Perfil',
    isSideMenu: false,
    key: 'profile_view',
    role: 'profile',
    path: '/gestao/perfil/:id/:view',
    layout: registerPagesLayout,
    element: <ProfileCreate />,
  },
  {
    title: 'Justificar Alerta',
    isSideMenu: false,
    key: 'alert_justify',
    role: 'alert',
    path: 'alertas/justificar/:id/:waterAlert',
    layout: registerPagesLayout,
    element: <AlertJustify />,
  },
  {
    title: 'Atualização remota',
    isSideMenu: true,
    key: 'remote_update',
    icon: <ScheduleIcon />,
    layout: mainLayout,
    children: [
      {
        title: 'Versão Firmware',
        isSideMenu: true,
        key: 'firmware_version',
        role: 'firmware_version',
        path: '/gestao/versao-firmware',
        layout: mainLayout,
        element: <FirmwareVersionDashboard />,
      },
      {
        title: 'Agendamento',
        isSideMenu: true,
        key: 'scheduling',
        role: 'user',
        path: '/atualizacao-remota/agendamento',
        layout: mainLayout,
        element: <ScheduleDashboard />,
      },
    ],
  },

  {
    title: 'Agendamento',
    isSideMenu: false,
    key: 'schedule-view',
    role: 'user',
    path: '/atualizacao-remota/agendamento/:id',
    layout: registerPagesLayout,
    element: <ScheduleView />,
  },
  {
    title: 'Agendamento',
    isSideMenu: false,
    key: 'schedule-create',
    role: 'user',
    path: '/atualizacao-remota/agendamento/cadastrar',
    layout: registerPagesLayout,
    element: <ScheduleCreate />,
  },
  {
    title: 'Versão Firmware',
    isSideMenu: false,
    key: 'firmware_version-create',
    role: 'user',
    path: '/gestao/versao-firmware/:id',
    layout: registerPagesLayout,
    element: <FirmwareVersionCreate />,
  },
  {
    title: 'Relatórios',
    isSideMenu: true,
    key: 'report',
    path: '/relatorio',
    roles: ['report'],
    icon: <ReportsIcon />,
    layout: mainLayout,
    element: <ReportsDashboard />,
  },
  {
    title: 'Relatórios',
    isSideMenu: false,
    key: 'report',
    path: '/relatorio/detalhamento/:tipo',
    roles: ['report'],
    icon: <ReportsIcon />,
    layout: mainLayout,
    element: <ReportDetails />,
  },
  {
    title: 'Relatórios',
    isSideMenu: false,
    key: 'report',
    path: 'viagem/detalhamento/:id',
    roles: ['report'],
    layout: mainLayout,
    element: <TravelDetails />,
  },
  {
    title: 'Relatório Analítico',
    isSideMenu: true,
    key: 'analytic_report',
    icon: <ReconciliationOutlined />,
    layout: mainLayout,
    children: [
      {
        title: 'Erros Betoneira',
        isSideMenu: true,
        key: 'mixer_analytic_report',
        role: 'mixer_analytic_report',
        path: '/relatorio/erros-betoneira',
        layout: mainLayout,
        element: <MixerReportAnalyticDashboard />,
      },
      {
        title: 'Erros Betoneira',
        isSideMenu: false,
        key: 'mixer_analytic_report_view',
        role: 'mixer_analytic_report_view',
        path: '/relatorio/erros-betoneira/:id',
        layout: mainLayout,
        element: <MixerReportAnalyticDeviceView />,
      },
      {
        title: 'Análise Viagem',
        isSideMenu: false,
        key: 'view_trip_analytic_report',
        role: 'view_trip_analytic_report',
        path: '/relatorio/analise-viagem/:idViagem?',
        layout: mainLayout,
        element: <TripAnalyticDashboard />,
      },
      {
        title: 'Análise Viagem',
        isSideMenu: true,
        key: 'view_trip_analytic_report',
        role: 'view_trip_analytic_report',
        path: '/relatorio/analise-viagem',
        layout: mainLayout,
        element: <TripAnalyticDashboard />,
      },
      {
        title: 'Viagens Erros',
        isSideMenu: false,
        key: 'trip_analytic_report',
        role: 'trip_analytic_report',
        path: '/relatorio/erros-viagem/:id',
        layout: mainLayout,
        element: <TripErrorAnalyticView />,
      },
      {
        title: 'Viagens Erros',
        isSideMenu: true,
        key: 'trip_analytic_report',
        role: 'trip_analytic_report',
        path: '/relatorio/erros-viagem',
        layout: mainLayout,
        element: <TripErrorAnalyticDashboard />,
      },
      {
        title: 'Remessas',
        isSideMenu: true,
        key: 'shipping_report',
        role: 'shipping_report',
        path: '/relatorio/remessas',
        layout: mainLayout,
        element: <ShippingListAnalyticDashboard />,
      },
    ],
  },
  {
    title: 'Login',
    layout: authLayout,
    isSideMenu: false,
    key: 'login',
    path: '/auth/login',
    element: <LoginPage />,
  },
  {
    title: 'Logout',
    layout: authLayout,
    isSideMenu: false,
    key: 'logout',
    path: '/logout',
    element: <Logout />,
  },
  {
    title: 'FirsAccess',
    layout: authLayout,
    isSideMenu: false,
    key: 'login',
    path: '/auth/first-access',
    element: <FirstAccessPage />,
  },
  {
    title: 'Signup',
    layout: authLayout,
    isSideMenu: false,
    key: 'sign-up',
    path: '/auth/sign-up',
    element: <SignUpPage />,
  },
  {
    title: 'Lock',
    layout: authLayout,
    isSideMenu: false,
    key: 'lock',
    path: '/auth/lock',
    element: (
      <RequireAuth>
        <LockPage />
      </RequireAuth>
    ),
  },
  {
    title: 'ForgotPassword',
    layout: authLayout,
    isSideMenu: false,
    key: 'forgot-password',
    path: '/auth/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    title: 'ForgotPasswordSuccess',
    layout: authLayout,
    isSideMenu: false,
    key: 'forgot-password-success',
    path: '/auth/forgot-password-success',
    element: <ForgotPasswordSucessPage />,
  },
  {
    title: 'NewPassword',
    layout: authLayout,
    isSideMenu: false,
    key: 'new-password',
    path: '/auth/new-password/:token',
    element: <NewPasswordPage />,
  },
  {
    title: '404',
    layout: mainLayout,
    icon: <AlertIcon />,
    isSideMenu: true,
    key: '404',
    path: '/404',
    element: <Error404Page />,
  },
  {
    title: '403',
    layout: mainLayout,
    icon: <AlertIcon />,
    isSideMenu: true,
    key: '403',
    path: '/403',
    element: <Error403Page />,
  },
  {
    title: '500',
    layout: mainLayout,
    icon: <AlertIcon />,
    isSideMenu: true,
    key: '500',
    path: '/500',
    element: <Error500Page />,
  },
];
