import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { ReportTripErrorItemAnalytic } from '@app/domain/interfaces/report/reportTripErrorAnalytic';
import { joinArrayToString, minutesToHoursAndMinutes } from '@app/utils/utils';

const headers: string[] = [
  'IdViagem',
  'DeviceId',
  'Betoneira',
  'TipoErro',
  'Gerado Resumo',
  'Tempo Início Viagem',
  'Espera excedida',
  'Estados',
  'Erros',
];

export const exportReportTripErrorAnalyticToExcel = async (report: ReportTripErrorItemAnalytic[]) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('data');

  worksheet.addRow(headers);

  report.map((r) => {
    worksheet.addRow([
      r.idViagem,
      r.deviceId,
      r.betoneira,
      r.statusErro,
      !r.semResumo ? 'Sim' : 'Não',
      minutesToHoursAndMinutes(r.tempoAguardoInicioViagem),
      r.tempoAguardoInicioViagem > 30 ? 'Sim' : 'Não',
      joinArrayToString(r.estados),
      joinArrayToString(r.erros),
    ]);
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  FileSaver.saveAs(blob, `relatorio_erros_viagem.xlsx`);
};
