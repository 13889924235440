import { FC, useCallback, useEffect, useState } from 'react';
import IReportService, { ReportService } from '@app/services/reportService';
import { notificationController } from '@app/controllers/notificationController';
import { ReportMixerErrorDeviceAnalyticModel } from '@app/domain/interfaces/report/reportMixerErrorAnalytic';
import { useParams } from 'react-router-dom';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeader } from '@app/store/slices/headerSlice';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Col, Row } from 'antd';
import { Badge } from '@app/components/common/Badge/Badge';
import moment from 'moment';
import ConcreteMixerTrackingMap from '@app/components/common/Map/ConcreteMixerTrackingMap/ConcreteMixerTrackingMap';
import { setConcreteMixerLocations } from '@app/store/slices/concreteMixerLocationsSlice';
import { renderViagemEstadoTexto } from '@app/utils/utils';

const reportService: IReportService = new ReportService();

export const MixerReportAnalyticDeviceView: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<ReportMixerErrorDeviceAnalyticModel>();

  const fetch = useCallback((id: string) => {
    setLoading(true);

    reportService
      .getMixerReportAnlyticByDeviceId(id)
      .then((res) => {
        setReport(res);
        if (res.ultimaLocalizacaoDataHora && res.ultimaLocalizacaoLat && res.ultimaLocalizacaoLng) {
          dispatch(
            setConcreteMixerLocations({
              listaInfoViagem: [
                {
                  deviceId: res.deviceId,
                  lat: res.ultimaLocalizacaoLat,
                  lng: res.ultimaLocalizacaoLng,
                  dataHora: res.ultimaLocalizacaoDataHora,
                  velocidadeBetoneira: 0,
                },
              ],
            }),
          );
        }
      })
      .catch((error) => notificationController.error(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) fetch(id);
    dispatch(setHeader({ title: 'Visualização de erros do dispositivo' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, id]);

  return (
    <>
      <Spinner spinning={loading} text="Carregando informações do dispositivo, por favor, aguarde." />
      <PageContainer>
        <CardInfoContainer>
          <Row>
            <CardInfoTitle>Informações do dispositivo</CardInfoTitle>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={4}>
              <CardInfoProperty>Imei</CardInfoProperty>
              <CardInfoValue>{report?.imei ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>DeviceId</CardInfoProperty>
              <CardInfoValue>{report?.deviceId ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Betoneira</CardInfoProperty>
              <CardInfoValue>{report?.betoneira ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Apn</CardInfoProperty>
              <CardInfoValue>{report?.apn ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>ICCID</CardInfoProperty>
              <CardInfoValue>{report?.iccid ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Online</CardInfoProperty>
              <CardInfoValue>
                {report?.online ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />}
              </CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Versão Central</CardInfoProperty>
              <CardInfoValue>{report?.versaoCentral ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Versão Hermes</CardInfoProperty>
              <CardInfoValue>{report?.versaoHermes ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Versão H2</CardInfoProperty>
              <CardInfoValue>{report?.versaoH2 ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Calibração H2</CardInfoProperty>
              <CardInfoValue>
                {report?.calibracaoH2 && report.calibracaoH2 != 0 ? report?.calibracaoH2 : '-'}
              </CardInfoValue>
            </Col>
            <Col xs={24} md={4}></Col>
            <Col xs={24} md={4}></Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Última Obra</CardInfoProperty>
              <CardInfoValue>{report?.obra ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Última Filial</CardInfoProperty>
              <CardInfoValue>{report?.filial ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Data/Hora últ. remessa</CardInfoProperty>
              <CardInfoValue>
                {report?.dataHoraUltimaRemessa
                  ? moment(report.dataHoraUltimaRemessa).format('DD/MM/YYYY HH:mm') + 'h'
                  : '-'}
              </CardInfoValue>
            </Col>
            <Col xs={24} md={4}>
              <CardInfoProperty>Último estado viagem</CardInfoProperty>
              <CardInfoValue>
                {report?.ultimaLocalizacaoEstadoViagemAtual} -{' '}
                {renderViagemEstadoTexto(Number(report?.ultimaLocalizacaoEstadoViagemAtual))}
              </CardInfoValue>
            </Col>
          </Row>
        </CardInfoContainer>
        <Row gutter={[16, 16]} style={{ width: '100%', padding: '2rem' }}>
          <Col xs={24} md={8}>
            <Row>
              <CardInfoTitle>Erros do dispositivo</CardInfoTitle>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <CardInfoProperty>Erro central</CardInfoProperty>
                <CardInfoValue>
                  {report?.erroCentral ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />}
                </CardInfoValue>
              </Col>
              <Col xs={24}>
                <CardInfoProperty>Erro sensor pressão de óleo</CardInfoProperty>
                <CardInfoValue>
                  {report?.erroSensorPressaoOleo ? (
                    <Badge color="green" text="Sim" />
                  ) : (
                    <Badge color="red" text="Não" />
                  )}
                </CardInfoValue>
              </Col>
              <Col xs={24}>
                <CardInfoProperty>Erro sensor pressão de ar</CardInfoProperty>
                <CardInfoValue>
                  {report?.erroSensorPressaoAr ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />}
                </CardInfoValue>
              </Col>
              <Col xs={24}>
                <CardInfoProperty>Erro sensor indutivo</CardInfoProperty>
                <CardInfoValue>
                  {report?.erroSensorIndutivo ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />}
                </CardInfoValue>
              </Col>
              <Col xs={24}>
                <CardInfoProperty>Erro na H2</CardInfoProperty>
                <CardInfoValue>
                  {report?.erroH2 ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />}
                </CardInfoValue>
              </Col>
              <Col xs={24}>
                <CardInfoProperty>Erro rede CAN</CardInfoProperty>
                <CardInfoValue>
                  {report?.erroRedeCan ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />}
                </CardInfoValue>
              </Col>
              <Col xs={24}>
                <CardInfoProperty>Erro GNSS</CardInfoProperty>
                <CardInfoValue>
                  {report?.erroRedeGnss ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />}
                </CardInfoValue>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={16}>
            <Row>
              <CardInfoTitle>Última localização do dispositivo</CardInfoTitle>
            </Row>
            <Row>
              <Col xs={24} md={8}>
                <CardInfoProperty>Últ. Localização Data/Hora</CardInfoProperty>
                <CardInfoValue>
                  {report?.ultimaLocalizacaoDataHora
                    ? moment(report.ultimaLocalizacaoDataHora).format('DD/MM/YYYY HH:mm') + 'h'
                    : '-'}
                </CardInfoValue>
              </Col>
              <Col xs={24} md={8}>
                <CardInfoProperty>Últ. Latitude</CardInfoProperty>
                <CardInfoValue>
                  {report?.ultimaLocalizacaoLat && report.ultimaLocalizacaoLat != 0 ? report.ultimaLocalizacaoLat : '-'}
                </CardInfoValue>
              </Col>
              <Col xs={24} md={8}>
                <CardInfoProperty>Últ. Longitude</CardInfoProperty>
                <CardInfoValue>
                  {report?.ultimaLocalizacaoLng && report.ultimaLocalizacaoLng != 0 ? report.ultimaLocalizacaoLng : '-'}
                </CardInfoValue>
              </Col>
              <Col xs={24} style={{ marginTop: '1rem', height: '30rem' }}>
                <ConcreteMixerTrackingMap />
              </Col>
            </Row>
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};
