import React from 'react';
import ApexCharts from 'apexcharts';
import { useEffect } from 'react';
import moment from 'moment';
import {
  getDifferenceBetweenDatesInHours,
  getDifferenceBetweenDatesInMinutes,
  renderViagemEstadoTexto,
} from '@app/utils/utils';
import MouseTracker from '@app/components/common/MouseTracker/MouseTracker';
import { getTooltipConfig } from '@app/pages/modules/Reports/Charts/getTooltipConfig';
import { ITooltipConfig } from '@app/pages/modules/Reports/Charts/interface/chartReport';
import { GraphicDataModel } from '@app/domain/interfaces/report/reportTripAnalyticModel';

interface IChartWaterUsed {
  graphicDetails: GraphicDataModel;
}

export const ChartWaterUsed: React.FC<IChartWaterUsed> = ({ graphicDetails }) => {
  const [tooltipConfig, setTooltipConfig] = React.useState<ITooltipConfig>({
    visible: false,
    title: '',
    value: '',
  });

  useEffect(() => {
    if (
      graphicDetails.usoAgua &&
      graphicDetails.usoAgua?.aguaMangueira &&
      graphicDetails.usoAgua?.aguaMangueira?.length > 0 &&
      graphicDetails.usoAgua.aguaBalao &&
      graphicDetails.usoAgua.aguaBalao.length > 0 &&
      graphicDetails.usoAgua.aguaBalaoLavagem &&
      graphicDetails.usoAgua.aguaBalaoLavagem.length > 0 &&
      graphicDetails.usoAgua.aguaMangueiraLavagem &&
      graphicDetails.usoAgua.aguaMangueiraLavagem.length > 0
    ) {
      const options = {
        chart: {
          id: 'water',
          type: 'line',
          group: 'chart',
          width: '100%',
          height: 600,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '14px',
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontStyle: 'normal',
          markers: {
            width: 24,
            height: 5,
            radius: 0,
          },
        },
        stroke: {
          curve: 'smooth',
          width: [2, 2, 1],
        },
        colors: ['#1c67b3', '#cc1821', '#c47227', '#52c225', '#cac719'],
        series: [
          {
            type: 'line',
            name: 'Linha 1 - Limite da água (L)',
            data: graphicDetails.usoAgua.aguaBalao
              ?.map((u) => ({
                y: graphicDetails.aguaRetida,
                x: u.dataHora ? new Date(u.dataHora).getTime() : 0,
              }))
              .sort((a, b) => b.x - a.x),
          },
          {
            type: 'line',
            name: 'Linha 2 - Água mangueira (L)',
            data: graphicDetails.usoAgua.aguaMangueira
              ?.filter((u) => u.dataHora !== undefined)
              ?.map((u) => ({ y: u.litros, x: u.dataHora ? new Date(u.dataHora).getTime() : 0 }))
              .sort((a, b) => b.x - a.x),
          },
          {
            type: 'line',
            name: 'Linha 3 - Água balão (L)',
            data: graphicDetails.usoAgua.aguaBalao
              ?.filter((u) => u.dataHora !== undefined)
              ?.map((u) => ({ y: u.litros, x: u.dataHora ? new Date(u.dataHora).getTime() : 0 }))
              .sort((a, b) => b.x - a.x),
          },
          {
            type: 'line',
            name: 'Linha 4 - Água mangueira lavagem (L)',
            data: graphicDetails.usoAgua.aguaMangueiraLavagem
              ?.filter((u) => u.dataHora !== undefined)
              ?.map((u) => ({ y: u.litros, x: u.dataHora ? new Date(u.dataHora).getTime() : 0 }))
              .sort((a, b) => b.x - a.x),
          },
          {
            type: 'line',
            name: 'Linha 5 - Água balão lavagem (L)',
            data: graphicDetails.usoAgua.aguaBalaoLavagem
              ?.filter((u) => u.dataHora !== undefined)
              ?.map((u) => ({ y: u.litros, x: u.dataHora ? new Date(u.dataHora).getTime() : 0 }))
              .sort((a, b) => b.x - a.x),
          },
        ],
        xaxis: {
          min: new Date(graphicDetails.estadosViagem[1]?.dataHora ?? 0).getTime(),
          max: new Date(graphicDetails.estadosViagem[graphicDetails.estadosViagem.length - 1]?.dataHora ?? 0).getTime(),
          type: 'datetime',
          tickAmount: 15,
          labels: {
            datetimeUTC: true,
            formatter: (value: number | number[]) =>
              typeof value == 'number' ? moment(value).format('HH:mm') : moment(value[0]).format('HH:mm'),
          },
          tooltip: {
            enabled: false,
          },
        },
        annotations: {
          xaxis: graphicDetails.estadosViagem
            ?.filter((estadoViagem) => estadoViagem.estadoViagemAtual && estadoViagem.estadoViagemAtual > 1)
            .map((estado) => {
              return {
                x: estado.dataHora ? new Date(estado.dataHora).getTime() : 0,
                strokeDashArray: 0,
                borderColor: '#5EB576',
                label: {
                  position: 'top',
                  orientation: 'vertical',
                  style: {
                    color: '#707070',
                    background: '#fff',
                    fontSize: '16px',
                    offsetX: 0,
                    offsetY: -30,
                    padding: {
                      left: 20,
                      right: 10,
                      top: 5,
                      bottom: 5,
                    },
                  },
                  text: renderViagemEstadoTexto(estado.estadoViagemAtual ?? 0),
                },
              };
            }),
          points: graphicDetails.temposViagemConsumo?.map((tempoViagem) => {
            return {
              x: tempoViagem.dataHoraEntreEstados ? new Date(tempoViagem.dataHoraEntreEstados).getTime() : 0,
              y: 0,
              marker: {
                size: 9,
                shape: 'square',
                fillColor: '#374450',
                strokeColor: '#374450',
                tooltip: {
                  enabled: true,
                },
              },
              mouseEnter: () => {
                setTooltipConfig(getTooltipConfig(tempoViagem));
              },
              mouseLeave: () => {
                setTooltipConfig({
                  title: '',
                  value: '',
                  visible: false,
                });
              },
            };
          }),
        },
      };
      const chart = new ApexCharts(document.querySelector('#chart_principal'), options);
      chart.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!(
        graphicDetails.usoAgua &&
        graphicDetails.usoAgua?.aguaMangueira &&
        graphicDetails.usoAgua?.aguaMangueira?.length > 0 &&
        graphicDetails.usoAgua.aguaBalao &&
        graphicDetails.usoAgua.aguaBalao.length > 0 &&
        graphicDetails.usoAgua.aguaBalaoLavagem &&
        graphicDetails.usoAgua.aguaBalaoLavagem.length > 0 &&
        graphicDetails.usoAgua.aguaMangueiraLavagem &&
        graphicDetails.usoAgua.aguaMangueiraLavagem.length > 0
      ) && <h3 style={{ paddingLeft: '1rem' }}>Nenhum dado disponível para exibição.</h3>}

      {tooltipConfig.visible && (
        <MouseTracker offset={{ x: 0, y: 0 }}>
          <div className="tooltip">
            <span className="square"></span>
            <span className="text-label">{tooltipConfig.title}</span>
            <span className="text-value">{tooltipConfig.value}</span>
          </div>
        </MouseTracker>
      )}
      <div id="chart_principal"></div>
    </>
  );
};
